import './App.css';

import Home from "./views/homepage/home.page";

function App() {
  return (
    <div>
      <Home/>
    </div>
  );
}

export default App;
